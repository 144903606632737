import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import { modalStyle, rowstyle } from "../styles/styles";
import {
  allergenViewModel,
  careInstructionViewModel,
  ingredientViewModel,
  productViewModel,
} from "../api";
import { currencyFormat } from "../utils/formatting";
import {
  GetAllergens,
  GetCareInstructions,
  GetIngredients,
  saveproduct,
} from "../utils/ApiUtils";
import { IsAdmin } from "../utils/MsGraphApiCall";
type editProps = {
  disabled: boolean;
  product: productViewModel;
  onDetailsSaved : ((product:productViewModel)=>void)
};
const EditProduct = (props: editProps) => {

    const saveitem = () => {
        productEditValue.isFood = productEditValue.ingredients.length > 0;
        productEditValue.proteinPerServing = productEditValue.proteinPer100 * (productEditValue.servingSize / 100);
        productEditValue.energyPerServing = productEditValue.energyPer100 * (productEditValue.servingSize / 100);
        productEditValue.totalFatPerServing = productEditValue.totalFatPer100 * (productEditValue.servingSize / 100);
        productEditValue.carbohydratesPerServing =
        productEditValue.carbohydratesPer100 * (productEditValue.servingSize / 100);
        productEditValue.sugarsPerServing = productEditValue.sugarsPer100 * (productEditValue.servingSize / 100);
        productEditValue.sodiumPerServing = productEditValue.sodiumPer100 * (productEditValue.servingSize / 100);
    
       
        saveproduct(productEditValue);
        setEditMode(false);
        props.onDetailsSaved(productEditValue);
       
      };
  const getIngredientsPromise = () => {
    GetIngredients().then((response) => {
      if (response !== null && typeof response === "object")
        setIngredientsList(response);
    });
  };
  const getCareInstructionsPromise = () => {
    GetCareInstructions().then((response) => {
      if (response !== null && typeof response === "object")
        setCareList(response);
    });
  };
  const getAllergensPromise = () => {
    GetAllergens().then((response) => {
      if (response !== null && typeof response === "object")
        setAllergenList(response);
    });
  };
  const [isAdmin, setIsAdmin] = React.useState<Boolean>(false);

  const [allergenList, setAllergenList] = React.useState<
    Array<allergenViewModel>
  >([]);
  const [carelist, setCareList] = React.useState<
    Array<careInstructionViewModel>
  >([]);
  const [ingredientsList, setIngredientsList] = React.useState<
    Array<ingredientViewModel>
  >([]);
  const [editmode, setEditMode] = React.useState<boolean>(false);
  const [productEditValue, setProductEditValue] =
    React.useState<productViewModel>(props.product);
  const handleEditClicked = async () => {
    console.log("Edit Clicked");
    getIngredientsPromise();
    getCareInstructionsPromise();
    getAllergensPromise();

    setProductEditValue(props.product);
    setEditMode(true);
  };

  React.useEffect(()=>{
    IsAdmin().then((response:boolean)=>{
      setIsAdmin(response)
    })
  },[setIsAdmin])
  return (
    <> 
 { props.product?.id && isAdmin && (
      <Button
        variant="outlined"
        disabled={props.disabled}
        onClick={async () => {
          await handleEditClicked();
        }}
        startIcon={<EditIcon />}
      >
        Edit
      </Button>
 )}
      <Modal open={editmode}>
        <Box
          sx={modalStyle}
          gap={1}
          p={1}
          my={1}
          maxWidth={1200}
          minWidth={1200}
        >
          <Card>
            <CardHeader
              title={
                productEditValue?.productName +
                " :" +
                currencyFormat(productEditValue?.price)
              }
              subheader={"Sku:" + productEditValue?.productSku}
            ></CardHeader>
            <CardContent>
              <Grid container columns={6}>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    label="Serving Size"
                    defaultValue={productEditValue?.servingSize}
                    onChange={(e) => {
                      if (e.target.value != null) {
                        productEditValue.servingSize = Number(e.target.value);
                        setProductEditValue(productEditValue);
                      }
                    }}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">g</InputAdornment>
                      ),
                    }}
                    placeholder={productEditValue?.servingUnit}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    id="edit-description"
                    fullWidth
                    label="Description"
                    multiline
                    onChange={(e) => {
                      if (e.target.value != null) {
                        productEditValue.productDescription = e.target.value;
                        setProductEditValue(productEditValue);
                      }
                    }}
                    rows={1}
                    defaultValue={productEditValue?.productDescription}
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    multiple
                    id="tags-standard"
                    options={ingredientsList.map(
                      (ingredient) => ingredient.ingredientName
                    )}
                    getOptionLabel={(option) => option}
                    onChange={(event, values) => {
                      console.log(values);
                      productEditValue.ingredients = values;
                      setProductEditValue(productEditValue);
                    }}
                    freeSolo
                    defaultValue={productEditValue?.ingredients}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                        {...props}
                      >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Ingredient list"
                        placeholder="Ingredient"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    multiple
                    id="tags-standard"
                    freeSolo
                    options={allergenList.map(
                      (allergen) => allergen.allergenName
                    )}
                    getOptionLabel={(option) => option}
                    defaultValue={productEditValue?.mayContain}
                    onChange={(e, v) => {
                      productEditValue.mayContain = v;
                      setProductEditValue(productEditValue);
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                        {...props}
                      >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="May contain list"
                        placeholder="allergens"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Autocomplete
                    fullWidth
                    multiple
                    freeSolo
                    id="tags-standard"
                    options={carelist.map((care) => care.instruction)}
                    getOptionLabel={(option) => option}
                    defaultValue={productEditValue?.careInstructions}
                    onChange={(e, v) => {
                      productEditValue.careInstructions = v;
                      setProductEditValue(productEditValue);
                    }}
                    disableCloseOnSelect
                    renderOption={(props, option, { selected }) => (
                      <MenuItem
                        key={option}
                        value={option}
                        sx={{ justifyContent: "space-between" }}
                        {...props}
                      >
                        {option}
                        {selected ? <CheckIcon color="info" /> : null}
                      </MenuItem>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Care Instructions"
                        placeholder="care instruction"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="Energy"
                    defaultValue={productEditValue?.energyPer100}
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.energyPer100 = Number(e.target.value);
                      setProductEditValue(productEditValue);
                    }}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.energyUnit}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={productEditValue?.servingUnit}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="Protein"
                    defaultValue={productEditValue?.proteinPer100}
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.proteinPer100 = Number(e.target.value);
                      setProductEditValue(productEditValue);
                    }}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.proteinUnit}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={productEditValue?.servingUnit}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="fat"
                    defaultValue={productEditValue?.totalFatPer100}
                    type="number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.totalFatPer100 = Number(e.target.value);
                      setProductEditValue(productEditValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.totalFatUnit}
                        </InputAdornment>
                      ),
                    }}
                    placeholder={productEditValue?.servingUnit}
                  />
                </Grid>

                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="Carbohydrates"
                    defaultValue={productEditValue?.carbohydratesPer100}
                    type="number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.carbohydratesPer100 = Number(
                        e.target.value
                      );
                      setProductEditValue(productEditValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.carbohydrateUnit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="Sugars"
                    defaultValue={productEditValue?.sugarsPer100}
                    type="number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.sugarsPer100 = Number(e.target.value);
                      setProductEditValue(productEditValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.sugarsUnit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <TextField
                    id="filled-number"
                    fullWidth
                    label="Sodium"
                    defaultValue={productEditValue?.sodiumPer100}
                    type="number"
                    onChange={(e) => {
                      console.log(e.target.value);
                      productEditValue.sodiumPer100 = Number(e.target.value);
                      setProductEditValue(productEditValue);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {productEditValue?.sodiumUnit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <ButtonGroup
                variant="outlined"
                aria-label="Loading button group"
                sx={{ m: 1 }}
              >
                <Button
                  variant="contained"
                  onClick={saveitem}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => setEditMode(false)}
                  startIcon={<CancelIcon />}
                >
                  cancel
                </Button>
              </ButtonGroup>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </>
  );
};

export default EditProduct;
