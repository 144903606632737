import { getgroups } from "process";
import { loginRequest, graphConfig, graphGroupConfig } from "../authConfig";
import { msalInstance } from "../index";
import { GroupData } from "../ui-components/ProfileData";
import React, { useEffect } from "react";

export async function callMsGraph() {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch(graphConfig.graphMeEndpoint, options)
        .then(response => response.json())
        .catch(error => console.log(error));
}


export async function GetGroups() : Promise<any> {
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account
    });

    const headers = new Headers();
    const bearer = `Bearer ${response.accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    var result =  fetch(graphGroupConfig.graphMeEndpoint, options)
        .then(response => response.json() )
        .catch(error => console.log(error));
        return result;
}

export   async function IsAdmin():  Promise<boolean>{
    let groupsresponse  =  await GetGroups();  
    let groups :GroupData[] = groupsresponse.value;
    console.log(groups)
    let adminRow = groups.find(item => item.id ==="f962ab98-013c-43ec-a285-d12bc9a88470")
    if (adminRow?.id)
        return true;
    return false;
}