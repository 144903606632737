import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  IconButtonProps,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PrintIcon from "@mui/icons-material/Print";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridRowsProp,
} from "@mui/x-data-grid";
import React from "react";
import {
  BagTransfer,
  GetPackingData,
  SyncbulkPacking,
  GetAuditLog,
} from "../utils/ApiUtils";
import {
  baggingTransferRequest,
  packingViewModel,
  printLogViewModel,
} from "../api";
import { readonlystylebox } from "../styles/styles";
import { productLogDetailResults } from "../api";
import { currencyFormat } from "../utils/formatting";
import PrintDialog from "./PrintDialogComponent";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));
export function PackingComponent() {
  const [wait, setWait] = React.useState<boolean>(false);
  const [waitMessage, setWaitMessage] = React.useState<string>("");
  const [batchNumber, setBatchNumber] = React.useState<string>("");
  const [packingData, setpackingData] = React.useState<packingViewModel[]>([]);
  const [NumberofLabels, setNumberofLabels] = React.useState<number>(0);
  const [numberofBagsPacked, setNumberOfBagsPAcked] = React.useState<number>(0);
  const [NumberofBagsToShop, setNumberofBagsToShop] = React.useState<number>(0);
  const [NumberofBagsTowarehouse, setNumberofBagsToWarehouse] =
    React.useState<number>(0);
  const [selectedRow, setSelectedRow] = React.useState<packingViewModel>();
  const [bestbeforedate, setbestbeforedate] = React.useState<string>("");
  const [servingsPerPackage, setservingsperpackage] = React.useState<number>(0);
  const [NumberOfBulkBags, setNumberofBulkBags] = React.useState<number>(0);
  const [Nutritionalexpanded, setNutritionalExpanded] = React.useState(false);
  const [Ingredientsexpanded, setIngredientsExpanded] = React.useState(false);
  const [Careexpanded, setCareExpanded] = React.useState(false);
  const [logs, setlogs] = React.useState<printLogViewModel[]>([]);
  const readonlystyleboxsmall = {
    fontWeight: "bold",
  };
  React.useEffect(() => {
    setWaitMessage("Please wait fetching bagging information");
    setWait(true);
    GetPackingData().then((response) => {
      if (response !== null && typeof response === "object") {
        setpackingData(response);
        setWaitMessage("");
        setWait(false);
      }
    });
  }, [setpackingData, setWait, setWaitMessage]);
  function GetNumberOfLabels(bulkbags: number, row: packingViewModel = null) {
    console.log(bulkbags * Math.round(selectedRow?.bagsPerBulk));
    let bagsPerBulk = selectedRow?.bagsPerBulk;
    if (row != null) {
      bagsPerBulk = row.bagsPerBulk;
    }
    return bulkbags * Math.round(bagsPerBulk);
  }
  function GetNumberOfBags(bulkbags: number, row: packingViewModel = null) {
    console.log(bulkbags * Math.floor(selectedRow?.bagsPerBulk));
    let bagsPerBulk = selectedRow?.bagsPerBulk;
    if (row != null) {
      bagsPerBulk = row.bagsPerBulk;
    }
    return bulkbags * Math.floor(bagsPerBulk);
  }
  function setBagsToLocations(
    warehouseQty: number,
    shopQty: number,
    PackedQty: number
  ) {
    // one of the two numbers must be zero
    console.log("Warehouse Qty:");
    console.log(warehouseQty);
    console.log(shopQty);
    console.log(PackedQty);
    if (PackedQty == 0) return;
    if (warehouseQty != 0 && shopQty != 0) return;
    if (warehouseQty != 0 && warehouseQty <= PackedQty) {
      setNumberofBagsToWarehouse(warehouseQty);
      setNumberofBagsToShop(PackedQty - warehouseQty);
    }
    if (shopQty != 0 && shopQty <= PackedQty) {
      setNumberofBagsToShop(shopQty);
      setNumberofBagsToWarehouse(PackedQty - shopQty);
    }
  }
  const columns: GridColDef[] = [
    {
      field: "sku",
      headerName: "Sku",

      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "productName",
      headerName: "Package Name",
      minWidth: 240,
      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "shopLevel",
      headerName: "Qty In Shop",

      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "shopReorderPoint",
      headerName: "Restock Point",

      headerClassName: "app-theme--header",
      resizable: true,
    },
    {
      field: "warehouseLevel",
      headerName: "Qty In stock",

      headerClassName: "app-theme--header",
      resizable: true,
    },

    {
      field: "bulkBagsLevel",
      headerName: "Bulk In Stock",

      headerClassName: "app-theme--header",
      resizable: true,
    },
  ];

  const handleSyncClick = async () => {
    setWaitMessage("Syncing  bulk products from Vend");
    setWait(true);
    SyncbulkPacking().then((response) => {
      if (response !== null && typeof response === "object")
        setpackingData(response);
      setWaitMessage("");
      setWait(false);
    });
  };
  const handleNutritionExpandClick = () => {
    setNutritionalExpanded(!Nutritionalexpanded);
  };

  const handleIngredientExpandClick = () => {
    setIngredientsExpanded(!Ingredientsexpanded);
  };
  const handleCareExpandClick = () => {
    setCareExpanded(!Careexpanded);
  };
  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    FetchPrintLogs(params.row.product.id);
    setNutritionalExpanded(false);
    setIngredientsExpanded(false);
    console.log("row selected");
    setSelectedRow(params.row);
    setNumberofBulkBags(1);
    setbestbeforedate("");
    setBatchNumber("");
    console.log("settingnumberof bags");
    setNumberofLabels(GetNumberOfLabels(1, params.row));
    setNumberOfBagsPAcked(GetNumberOfBags(1, params.row));
    setBagsToLocations(
      GetNumberOfBags(1, params.row),
      0,
      GetNumberOfBags(1, params.row)
    );
    setservingsperpackage(
      params.row?.product.packingSize / params.row?.product.servingSize
    );
  };

  const FetchPrintLogs = React.useCallback(
    async (productid: string) => {
      const result = await GetAuditLog(productid).then(
        (response: productLogDetailResults) => {
          return response;
        }
      );
      setlogs(result?.printLogs);
    },
    [setlogs]
  );
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={wait}
      >
        <Typography>
          <h1>{waitMessage}</h1>
        </Typography>
        <br />
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box gap={1} p={1} my={1} width="100%">
        <ButtonGroup
          size="small"
          variant="outlined"
          aria-label="Loading button group"
          sx={{ m: 1, padding: 1, maxWidth: 1400, minWidth: 1400 }}
        >
          <Button
            onClick={async () => {
              await handleSyncClick();
            }}
            startIcon={<RefreshIcon />}
          >
            Sync packing data
          </Button>
        </ButtonGroup>

        <Grid container columns={7} spacing={0.5} sx={{ m: 0, padding: 1 }}>
          <Grid item xs={3}>
            <DataGrid
              sx={{
                "& .app-theme--header": {
                  backgroundColor: "primary.dark",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                  color: "white",
                  backgroundColor: "primary.dark",
                },
                "& .MuiDataGrid-columnHeader": {
                  // Forced to use important since overriding inline styles
                  height: "unset !important",
                },
                "& .MuiDataGrid-columnHeaders": {
                  // Forced to use important since overriding inline styles
                  maxHeight: "168px !important",
                },
              }}
              onRowClick={handleRowClick}
              rows={packingData}
              columns={columns}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                includeOutliers: true,
                includeHeaders: true,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            {selectedRow?.id && (
              <Card sx={{ maxWidth: "85%" }}>
                <CardHeader
                  title={
                    selectedRow?.productName +
                    " :" +
                    currencyFormat(selectedRow?.product.price)
                  }
                  subheader={"Sku:" + selectedRow?.sku}
                ></CardHeader>
                <CardContent>
                  {selectedRow?.id && (
                    <TextField
                      size="small"
                      id="labels"
                      label="Number of Bulk Bags"
                      type="number"
                      inputProps={{
                        min: "1",
                      }}
                      value={NumberOfBulkBags}
                      onChange={(e) => {
                        if (
                          parseInt(e.target.value) >
                            selectedRow?.bulkBagsLevel ||
                          parseInt(e.target.value) < 0
                        )
                          return;
                        setNumberofBulkBags(parseInt(e.target.value));
                        setNumberofLabels(
                          GetNumberOfLabels(parseInt(e.target.value))
                        );

                        setNumberOfBagsPAcked(
                          GetNumberOfBags(parseInt(e.target.value))
                        );
                        setBagsToLocations(
                          GetNumberOfBags(parseInt(e.target.value)),
                          0,
                          GetNumberOfBags(parseInt(e.target.value))
                        );
                      }}
                    ></TextField>
                  )}
                  <Grid
                    container
                    columns={2}
                    sx={{
                      "--Grid-borderWidth": "1px",
                      borderTop: "var(--Grid-borderWidth) solid",
                      borderLeft: "var(--Grid-borderWidth) solid",
                      borderColor: "divider",
                      "& > div": {
                        borderRight: "var(--Grid-borderWidth) solid",
                        borderBottom: "var(--Grid-borderWidth) solid",
                        borderColor: "divider",
                      },
                    }}
                  >
                    <Grid item xs={1}>
                      <PrintDialog
                        onbestBeforeDateChangedValue={(date: string) => {
                          setbestbeforedate(date);
                        }}
                        onBatchNumberChangedValue={(batch: string) => {
                          setBatchNumber(batch);
                        }}
                        onNumberofLabelsChangedValue={(labels: number) => {
                          setNumberofLabels(labels);
                        }}
                        printLogs={logs}
                        product={selectedRow?.product}
                        batchNumber={batchNumber}
                        
                        bestBeforeDate={bestbeforedate}
                       
                        NumberOfLabels={NumberofLabels}
                      
                      ></PrintDialog>
                    </Grid>
                    <Grid item xs={1}>
                      <Stack padding={1} spacing={2}>
                        {selectedRow?.id && (
                          <TextField
                            size="small"
                            fullWidth
                            id="labels"
                            label="Bags Packed"
                            type="number"
                            value={numberofBagsPacked}
                            onChange={(e) => {
                              setNumberOfBagsPAcked(parseInt(e.target.value));
                              setBagsToLocations(
                                parseInt(e.target.value),
                                0,
                                parseInt(e.target.value)
                              );
                            }}
                          ></TextField>
                        )}
                        {selectedRow?.id && (
                          <TextField
                            size="small"
                            fullWidth
                            id="labels"
                            label="Transfer to Shop"
                            type="number"
                            value={NumberofBagsToShop}
                            onChange={(e) =>
                              setBagsToLocations(
                                0,
                                parseInt(e.target.value),
                                numberofBagsPacked
                              )
                            }
                          ></TextField>
                        )}
                        {selectedRow?.id && (
                          <TextField
                            size="small"
                            fullWidth
                            id="labels"
                            label="Transfer to Warehouse"
                            type="number"
                            value={NumberofBagsTowarehouse}
                            onChange={(e) =>
                              setBagsToLocations(
                                parseInt(e.target.value),
                                0,
                                numberofBagsPacked
                              )
                            }
                          ></TextField>
                        )}
                        {selectedRow?.id && (
                          <Button
                            variant="contained"
                            onClick={async () => {
                              let request: baggingTransferRequest = {
                                bagProductId: selectedRow.product?.id,
                                bulkBags: NumberOfBulkBags,
                                bulkProductId: selectedRow.bulkProductId,

                                transferToShop: NumberofBagsToShop,
                                transferToWarehouse: NumberofBagsTowarehouse,
                              };
                              setWaitMessage(
                                "Please wait updating stock levels "
                              );
                              setWait(true);
                              BagTransfer(request).then(() => {
                                GetPackingData().then((response) => {
                                  if (
                                    response !== null &&
                                    typeof response === "object"
                                  )
                                    setpackingData(response);
                                  setWait(false);
                                });
                              });
                            }}
                            startIcon={<PrintIcon />}
                          >
                            Transfer
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>

                <CardActions>
                  {selectedRow?.id && (
                    <Typography variant="h5">Ingredients</Typography>
                  )}
                  <ExpandMore
                    expand={Ingredientsexpanded}
                    onClick={handleIngredientExpandClick}
                    aria-expanded={Ingredientsexpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={Ingredientsexpanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid container columns={6}>
                      <Grid item xs={6}>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            fullWidth
                            multiline
                            value={selectedRow?.product.ingredients.join(",")}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  Ingredients:
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
                <CardActions>
                  {selectedRow?.id && (
                    <Typography variant="h5">Care & Allergens</Typography>
                  )}
                  <ExpandMore
                    expand={Careexpanded}
                    onClick={handleCareExpandClick}
                    aria-expanded={Careexpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={Careexpanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid
                      container
                      columns={6}
                      spacing={0.5}
                      sx={{ m: 0, padding: 1 }}
                    >
                      <Grid item xs={6}>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            fullWidth
                            multiline
                            value={selectedRow?.product.mayContain.join(",")}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  May Contain:
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            fullWidth
                            multiline
                            value={selectedRow?.product.contains.join(",")}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  Contains:
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            fullWidth
                            multiline
                            value={selectedRow?.product.careInstructions.join(
                              ","
                            )}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  Care Instructions:
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
                <CardActions>
                  {selectedRow?.id && (
                    <Typography variant="h5">
                      Nutritional Information
                    </Typography>
                  )}
                  <ExpandMore
                    expand={Nutritionalexpanded}
                    onClick={handleNutritionExpandClick}
                    aria-expanded={Nutritionalexpanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </CardActions>
                <Collapse in={Nutritionalexpanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Grid
                      container
                      columns={2}
                      xs={2}
                      spacing={0}
                      sx={{ m: 0, padding: 1 }}
                    >
                      <Grid item>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            type="number"
                            value={servingsPerPackage.toFixed(2)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  Servings:
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                      <Grid item xs={2}>
                        {selectedRow?.product.isFood && (
                          <TextField
                            size="small"
                            disabled
                            type="number"
                            value={selectedRow?.product.servingSize}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystylebox}
                                >
                                  serving size:
                                </InputAdornment>
                              ),

                              endAdornment: (
                                <InputAdornment
                                  position="start"
                                  sx={readonlystyleboxsmall}
                                >
                                  {selectedRow?.product.servingUnit}
                                </InputAdornment>
                              ),
                            }}
                          ></TextField>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      columns={4}
                      spacing={0.5}
                      sx={{
                        "--Grid-borderWidth": "1px",
                        borderTop: "var(--Grid-borderWidth) solid",
                        borderLeft: "var(--Grid-borderWidth) solid",
                        borderColor: "divider",
                        "& > div": {
                          borderRight: "var(--Grid-borderWidth) solid",
                          borderBottom: "var(--Grid-borderWidth) solid",
                          borderColor: "divider",
                        },
                      }}
                    >
                      <Grid item xs={2}>
                        <Typography> </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography> Quantity Per Serving</Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography> Quantity Per 100g</Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          Energy {selectedRow?.product.energyUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.energyPerServing.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.energyPer100.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          Protein {selectedRow?.product.proteinUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.proteinPerServing.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {" "}
                          {selectedRow?.product.proteinPer100.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          Fat {selectedRow?.product.totalFatUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.totalFatPerServing.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {" "}
                          {selectedRow?.product.totalFatPer100.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          Carbohydrates {selectedRow?.product.carbohydrateUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.carbohydratesPerServing.toFixed(
                            2
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {" "}
                          {selectedRow?.product.carbohydratesPer100.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          Sugar {selectedRow?.product.sugarsUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.sugarsPerServing.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {" "}
                          {selectedRow?.product.sugarsPer100.toFixed(2)}
                        </Typography>
                      </Grid>

                      <Grid item xs={2}>
                        <Typography>
                          sodium {selectedRow?.product.sodiumUnit}{" "}
                        </Typography>
                      </Grid>

                      <Grid item xs={1}>
                        <Typography align="center">
                          {selectedRow?.product.sodiumPerServing.toFixed(2)}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <Typography align="center">
                          {" "}
                          {selectedRow?.product.sodiumPer100.toFixed(2)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Collapse>
              </Card>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
