import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { IsAdmin } from "../utils/MsGraphApiCall";
import {
  AppBar,
  Container,
  Toolbar,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },

  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));
type PageDefintion = {
  pageName: string;
  pagePath: string;
  disabled: boolean;
  visible: boolean;
};
function NavBar() {
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);
    React.useEffect(() => {
      IsAdmin().then((response: boolean) => {
        setIsAdmin(response);
      });
    }, [setIsAdmin]);
  const classes = useStyles();
  let pages: PageDefintion[] = [
   
    {
      pageName: "Print Labels",
      pagePath: "/labelPrinting",
      disabled: false,
      visible: true,
    },
    {
      pageName: "Packing",
      pagePath: "/packing",
      disabled: false,
      visible: true,
    },
    {
      pageName: "Suppliers",
      pagePath: "/Suppliers",
      disabled: false,
      visible:  isAdmin,
    },
    {
      pageName: "Ingredients",
      pagePath: "/Ingredients",
      disabled: false,
      visible:  isAdmin,
    } 
  ];
  //  const pages = ["/Bagging", "/Suppliers", "/Ingredients" ];
  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map(
                (page) =>
                  page.visible && (
                    <Button
                      disabled={page.disabled}
                      component={Link}
                      to={page.pagePath}
                      sx={{ my: 2, color: "white", display: "block" }}
                    >
                      {page.pageName}
                    </Button>
                  )
              )}
            </Box>

            <WelcomeName />
            <SignInSignOutButton />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default NavBar;
