import React from "react";
import Divider from "@mui/material/Divider";
import { productLogDetailResults } from "../api";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";

import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GetConfig,
  GetProductsList,
  GetSupplierIngredients,
  saveproduct,
  Syncproducts,
  GetAuditLog,
} from "../utils/ApiUtils";

import {
  configurationViewModel,
  productViewModel,
  ingredientViewModel,
  allergenViewModel,
  careInstructionViewModel,
  supplierIngredientsViewModel,
  printLogViewModel,
} from "../api";

import CheckIcon from "@mui/icons-material/Check";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { modalStyle, readonlystylebox, rowstyle } from "../styles/styles";

import EditProduct from "./EditProduct";
import PrintDialog from "./PrintDialogComponent";
import { IsAdmin } from "../utils/MsGraphApiCall";

export default function Productdetails() {
  //#region State
  const [productList, setProductList] =
    React.useState<Array<productViewModel> | null>(null);

  const [editmode, setEditMode] = React.useState<boolean>(false);
  const [openAutoComplete, setopenAutoComplete] =
    React.useState<boolean>(false);
  const [value, setValue] = React.useState<productViewModel | null>(null);
  const [servingsPerPackage, setservingsperpackage] = React.useState<number>(0);
  const [batchNumber, setBatchNumber] = React.useState<string>("");
  const [packedsize, setpackedsize] = React.useState<number>(0);
  const [bestbeforedate, setbestbeforedate] = React.useState<string>("");
  const [NumberofLabels, setNumberofLabels] = React.useState<number>(0);
  const [supplierCode, setSupplierCode] = React.useState<string>("");
  const [labeltemplate, setlabeltemplate] = React.useState<string>("");

  const [SupplierFetchedItem, setSupplierFetchedItem] =
    React.useState<supplierIngredientsViewModel | null>(null);
  const [ShowSupplierFetchedModal, setSupplierFetchedModal] =
    React.useState<boolean>(false);
  const [wait, setWait] = React.useState(false);
  const [updatevendTags, setupdateVendTags] = React.useState(false);
  const [logs, setlogs] = React.useState<printLogViewModel[]>([]);
  //#endregion
  //#region Refresh
  const FetchPrintLogs = React.useCallback(
    async (productid: string) => {
      const result = await GetAuditLog(productid).then(
        (response: productLogDetailResults) => {
          return response;
        }
      );
      setlogs(result?.printLogs);
    },
    [setlogs]
  );

  const refreshreadonlydata = (data: productViewModel) => {
    FetchPrintLogs(data.id);
    console.log("refreshing data");
    if (data == null) {
      setValue(null);
      return;
    }

    setValue(data);
    setpackedsize(data?.packingSize);

    setservingsperpackage(data?.packingSize / data?.servingSize);

    setBatchNumber("");

    setbestbeforedate("");
    setNumberofLabels(1);
    setSupplierCode(data.supplierCode);
    if (value?.supplierCode != null) setSupplierCode(value?.supplierCode);
    else setSupplierCode("");
    console.log("finished refreshing ");
  };

  //#endregion

  //#region Fetch On Load

  React.useEffect(() => {
    GetProductsList().then((response) => {
      if (response !== null && typeof response === "object")
        setProductList(response);
    });
  }, [setProductList]);

  //#endregion

  //#region Event Handlers
  const handleUpdateVendTagsChange = (event: any) => {
    setupdateVendTags(event.target.checked);
  };

  function packageWeightChangedEvent(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log("package weight change event");
    setpackedsize(parseInt(e.target.value));
    setservingsperpackage(parseInt(e.target.value) / value.servingSize);
  }

  const syncItem = (selectedValue: productViewModel) => {
    setWait(true);
    Syncproducts().then((respones) => {
      GetProductsList().then((response) => {
        if (response !== null && typeof response === "object")
          setProductList(response);
        console.log(value.price);

        if (selectedValue != null) {
          const newvalue = productList.find((element) => {
            return element.id == selectedValue.id;
          });
          console.log(newvalue.price);
          refreshreadonlydata(newvalue);
        }
        setWait(false);
      });
    });
  };

  const FetchSupplierdetails = React.useCallback(
    async (supplierid: string) => {
      const result = await GetSupplierIngredients(
        supplierid,
        supplierCode
      ).then((res: supplierIngredientsViewModel) => {
        return res;
        /*
        value.ingredients = res.ingredients;
        value.carbohydratesPer100 = res.carbohydratesValue;
        value.energyPer100 = res.energyValue;
        value.proteinPer100 = res.proteinValue;
        value.totalFatPer100 = res.fatValue;
        value.sugarsPer100 = res.sugarsValue;
        value.sodiumPer100 = res.sodiumValue;
        value.mayContain = res.mayContain.split(",");

        value.isFood = true;
         refreshreadonlydata(value);
        setValue(value);*/
        // setupdateVendTags(SupplierFetchedItem?.dietary.length > 1);
      });
      setSupplierFetchedItem(result);
      setupdateVendTags(result?.dietary.length > 1);
      setSupplierFetchedModal(true);
    },
    [
      setSupplierFetchedItem,
      setupdateVendTags,
      setSupplierFetchedModal,
      supplierCode,
    ]
  );

  const saveitem = () => {
    value.isFood = value.ingredients.length > 0;
    value.proteinPerServing = value.proteinPer100 * (value.servingSize / 100);
    value.energyPerServing = value.energyPer100 * (value.servingSize / 100);
    value.totalFatPerServing = value.totalFatPer100 * (value.servingSize / 100);
    value.carbohydratesPerServing =
      value.carbohydratesPer100 * (value.servingSize / 100);
    value.sugarsPerServing = value.sugarsPer100 * (value.servingSize / 100);
    value.sodiumPerServing = value.sodiumPer100 * (value.servingSize / 100);

    console.log(value);
    console.log(productList);
    const newlist = productList.map((item) => {
      if (item.id == value.id) {
        item.productDescription = value.productDescription;
        item.servingSize = value.servingSize;
        item.ingredients = value.ingredients;
        item.mayContain = value.mayContain;
        item.careInstructions = value.careInstructions;
        item.energyPer100 = value.energyPer100;
        item.energyPerServing = value.energyPerServing;
        item.proteinPer100 = value.proteinPer100;
        item.proteinPerServing = value.proteinPerServing;
        item.totalFatPer100 = value.totalFatPer100;
        item.totalFatPerServing = value.totalFatPerServing;
        item.carbohydratesPer100 = value.carbohydratesPer100;
        item.carbohydratesPerServing = value.carbohydratesPerServing;
        item.sugarsPer100 = value.sugarsPer100;
        item.sugarsPerServing = value.sugarsPerServing;
        item.sodiumPer100 = value.sodiumPer100;
        item.sodiumPerServing = value.sodiumPerServing;
      }

      return item;
    });

    saveproduct(value);

    setProductList(newlist);
    console.log("products List");

    refreshreadonlydata(value);
    console.log("refreshedData");
    setEditMode(false);
    setSupplierFetchedModal(false);
  };

  const handleAutoCompleteOpen = () => {
    if (productList != null) {
      setopenAutoComplete(true);
    }
  };
  //#endregion
  const [isAdmin, setIsAdmin] = React.useState<Boolean>(false);
  //#region Formatters

  function currencyFormat(num: Number) {
    if (num)
      return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  //#endregion

  //#region FetchData

  //#endregion

  //#region style
  const readonlystyleboxsmall = {
    fontWeight: "bold",
  };
  //#endregion
  React.useEffect(() => {
    IsAdmin().then((response: boolean) => {
      setIsAdmin(response);
    });
  }, [setIsAdmin]);
  return (
    <>
      <Modal open={ShowSupplierFetchedModal}>
        <Box
          sx={modalStyle}
          gap={1}
          p={1}
          my={1}
          maxWidth={1000}
          minWidth={1000}
        >
          <div className="p-1">
            <Grid container columns={7} spacing={0.2}>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  fullWidth
                  label="Title"
                  disabled={true}
                  defaultValue={SupplierFetchedItem?.title}
                  type="string"
                  placeholder={SupplierFetchedItem?.title}
                />
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Ingredients"
                  value={SupplierFetchedItem?.ingredients.join(",")}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="May Contain"
                  value={SupplierFetchedItem?.mayContain}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Energy"
                  value={SupplierFetchedItem?.energyValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.energyUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Protein"
                  value={SupplierFetchedItem?.proteinValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.proteinUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Fat"
                  value={SupplierFetchedItem?.fatValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.fatUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Carbohydrates"
                  value={SupplierFetchedItem?.carbohydratesValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.carbohydratesUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Sugar"
                  value={SupplierFetchedItem?.sugarsValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.sugarsUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Sodium"
                  value={SupplierFetchedItem?.sodiumValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.sodiumUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Care Instructions"
                  value={SupplierFetchedItem?.careInstructions.join(", ")}
                ></TextField>
              </Grid>

              <Grid item sx={rowstyle} xs={5}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Dietry"
                  value={SupplierFetchedItem?.dietary.join(", ")}
                ></TextField>
              </Grid>

              <Grid item sx={rowstyle} xs={1}>
                <FormControlLabel
                  control={<Checkbox checked={updatevendTags} />}
                  onChange={handleUpdateVendTagsChange}
                  label="Update Vend Tags"
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => {
                value.ingredients = SupplierFetchedItem.ingredients;
                value.carbohydratesPer100 =
                  SupplierFetchedItem.carbohydratesValue;
                value.energyPer100 = SupplierFetchedItem.energyValue;
                value.proteinPer100 = SupplierFetchedItem.proteinValue;
                value.totalFatPer100 = SupplierFetchedItem.fatValue;
                value.sugarsPer100 = SupplierFetchedItem.sugarsValue;
                value.sodiumPer100 = SupplierFetchedItem.sodiumValue;
                value.mayContain = SupplierFetchedItem.mayContain;
                value.careInstructions = SupplierFetchedItem.careInstructions;
                value.servingSize = SupplierFetchedItem.servingSize;
                value.servingUnit = SupplierFetchedItem.servingUnit;
                value.isFood = true;
                value.supplierCode = supplierCode;
                saveitem();
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSupplierFetchedModal(false)}
              startIcon={<CancelIcon />}
            >
              cancel
            </Button>
          </div>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={wait}
      >
        <Typography>
          <h1>Please wait Refreshing from Vend </h1>
        </Typography>
        <br />
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box gap={1} p={1} my={1} maxWidth={1000} minWidth={1000}>
        <Box>
          <Grid container columns={11} spacing={0.5} sx={{ m: 0, padding: 1 }}>
            <Grid item xs={6}>
              <Autocomplete
                id="Id"
                options={productList}
                onChange={(event, newValue) => refreshreadonlydata(newValue)}
                getOptionLabel={(option) =>
                  `${option.productName} | ${option.supplier} (${option.productSku})`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label="Product" />
                )}
                onOpen={handleAutoCompleteOpen}
                onClose={() => setopenAutoComplete(false)}
                open={openAutoComplete}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1}>
              {isAdmin && (
                <Button
                  variant="text"
                  onClick={() => {
                    syncItem(value);
                  }}
                  startIcon={<RefreshIcon />}
                >
                  Sync
                </Button>
              )}
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled
                value={value?.productSku}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" sx={readonlystylebox}>
                      sku
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled
                value={currencyFormat(value?.price)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={readonlystylebox}>
                      Price
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={11}>
              <TextField
                disabled
                fullWidth
                multiline
                value={value?.productDescription ?? ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={readonlystylebox}>
                      Description:
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.ingredients.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Ingredients:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.mayContain.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        May Contain:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.contains.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Contains:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.careInstructions.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Care Instructions:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={3}>
              <Stack padding={1} spacing={2}>
                {value?.isFood && (
                  <TextField
                    size="small"
                    onChange={packageWeightChangedEvent}
                    id="packedWeight"
                    label="Packed Weight"
                    type="number"
                    value={packedsize}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">g</InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}
                {value?.isFood && (
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    type="number"
                    value={value?.servingSize}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={readonlystylebox}>
                          serving size:
                        </InputAdornment>
                      ),

                      endAdornment: (
                        <InputAdornment
                          position="start"
                          sx={readonlystyleboxsmall}
                        >
                          {value?.servingUnit}
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}
                {value?.isFood && (
                  <TextField
                    size="small"
                    disabled
                    fullWidth
                    type="number"
                    value={servingsPerPackage.toFixed(2)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" sx={readonlystylebox}>
                          Servings:
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                )}

                <EditProduct
                  disabled={value == null}
                  product={value}
                  onDetailsSaved={(product) => {
                    const newlist = productList.map((item) => {
                      if (item.id == product.id) {
                        item.productDescription = product.productDescription;
                        item.servingSize = product.servingSize;
                        item.ingredients = product.ingredients;
                        item.mayContain = product.mayContain;
                        item.careInstructions = product.careInstructions;
                        item.energyPer100 = product.energyPer100;
                        item.energyPerServing = product.energyPerServing;
                        item.proteinPer100 = product.proteinPer100;
                        item.proteinPerServing = product.proteinPerServing;
                        item.totalFatPer100 = product.totalFatPer100;
                        item.totalFatPerServing = product.totalFatPerServing;
                        item.carbohydratesPer100 = product.carbohydratesPer100;
                        item.carbohydratesPerServing =
                          product.carbohydratesPerServing;
                        item.sugarsPer100 = product.sugarsPer100;
                        item.sugarsPerServing = product.sugarsPerServing;
                        item.sodiumPer100 = product.sodiumPer100;
                        item.sodiumPerServing = product.sodiumPerServing;
                      }

                      return item;
                    });
                    setProductList(newlist);
                    console.log("products List");

                    refreshreadonlydata(product);
                    console.log("refreshedData");
                    setEditMode(false);
                    setSupplierFetchedModal(false);
                  }}
                ></EditProduct>
              </Stack>
            </Grid>

            <Grid item xs={3}>
              <PrintDialog
                printLogs={logs}
                product={value}
                batchNumber={batchNumber}
                bestBeforeDate={bestbeforedate}
                onbestBeforeDateChangedValue={(date: string) => {
                  setbestbeforedate(date);
                }}
                onBatchNumberChangedValue={(batch: string) => {
                  setBatchNumber(batch);
                }}
                onNumberofLabelsChangedValue={(labels: string) => {
                  console.log(labels);
                  setNumberofLabels(parseInt(labels));
                }}
                NumberOfLabels={NumberofLabels}
              ></PrintDialog>
            </Grid>
            <Grid item xs={3}>
              {isAdmin && (
                <Stack padding={1} spacing={2}>
                  <TextField
                    size="small"
                    id="Supplier-Code"
                    label="Supplier Code"
                    value={supplierCode}
                    onChange={(e) => {
                      if (e.target.value != null) {
                        console.log(e.target.value);
                        setSupplierCode(e.target.value);
                      }
                    }}
                    InputProps={{}}
                  />

                  <Button
                    disabled={supplierCode?.trim() == ""}
                    variant="outlined"
                    onClick={() => {
                      FetchSupplierdetails(value.supplierId);
                    }}
                    startIcon={<DownloadIcon />}
                  ></Button>
                </Stack>
              )}
            </Grid>
          </Grid>
        </Box>

        <br></br>
        <br></br>

        {value?.isFood && (
          <Grid
            container
            columns={4}
            spacing={0.5}
            sx={{
              "--Grid-borderWidth": "1px",
              borderTop: "var(--Grid-borderWidth) solid",
              borderLeft: "var(--Grid-borderWidth) solid",
              borderColor: "divider",
              "& > div": {
                borderRight: "var(--Grid-borderWidth) solid",
                borderBottom: "var(--Grid-borderWidth) solid",
                borderColor: "divider",
              },
            }}
          >
            <Grid item xs={4}>
              <Typography>Nutrition Information</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography> </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography> Quantity Per Serving</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography> Quantity Per 100g</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Energy {value?.energyUnit} </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.energyPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.energyPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Protein {value?.proteinUnit}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.proteinPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.proteinPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Fat {value?.totalFatUnit}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.totalFatPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.totalFatPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Carbohydrates {value?.carbohydrateUnit}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>
                {value?.carbohydratesPerServing.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.carbohydratesPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Sugar {value?.sugarsUnit}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.sugarsPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.sugarsPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography>Sodium {value?.sodiumUnit}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.sodiumPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{value?.sodiumPer100.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
